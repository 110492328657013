import {
  assignTranslation,
  isPromptTranslated,
  PromptDefinition,
} from "@xflr6/chatbot-engine";
import classNames from "classnames";
import React, {
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Draggable,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import { useInView } from "react-hook-inview";
import { BsExclamationCircle } from "react-icons/bs";
import { FaRegTrashAlt, FaReplyAll, FaWrench } from "react-icons/fa";
import { GrDrag } from "react-icons/gr";
import { IoDuplicateOutline } from "react-icons/io5";
import { MdMoreVert } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ArrowContainer } from "react-tiny-popover";

import { RootState } from "../../../app/store";
import { ReactComponent as ImageUploadIcon } from "../../../assets/imageUploadIcon.svg";
import { ReactComponent as QuestionAnswerIcon } from "../../../assets/questionAnswerIcon.svg";
import FlatButton from "../../../components/buttons/FlatButton";
import HelpTip from "../../../components/HelpTip";
import { Marker } from "../../../components/MarkerBar";
import Menu from "../../../components/menus/Menu";
import MenuItem from "../../../components/menus/MenuItem";
import MyTippy from "../../../components/MyTippy";
import Popover from "../../../components/Popover";
import TranslateIcon from "../../../featuresCommon/TranslateIcon";
import useDelayedToggle from "../../../utils/useDelayedToggle";
import {
  EditorUpdatePromptFields,
  initPromptQuickResponses,
  insertAnswer,
  insertPrompt,
  previewPrompt,
  removePrompt,
  selectCurrentLanguage,
  updatePromptFields,
} from "../flowEditorSlice";
import {
  selectIsPromptConfigOpen,
  setIsPromptConfigOpen,
} from "../flowEditorUiSlice";
import { selectPromptEasyNames } from "../selectPromptEasyNames";
import AltMessagesEditor, {
  AltMessagesEditorCtrl,
} from "./altMessagesEditor/AltMessagesEditor";
import AnswersEditor, {
  AnswersEditorCtrl,
} from "./answersEditors/AnswersEditor";
import InsertPromptButton from "./InsertPromptButton";
import MessageEditor, { MessageEditorCtrl } from "./MessageEditor";
import MessagesEditor, { MessagesEditorCtrl } from "./MessagesEditor";
import NextKeyEditor from "./NextKeyEditor";
import * as ops from "./operations";
import styles from "./PromptEditor.module.css";

export interface PromptEditorCtrl {
  key: string;
  index: number;
  focus: () => void;
  answers: () => AnswersEditorCtrl | undefined;
  messages: () => MessagesEditorCtrl | undefined;
  altMessages: () => AltMessagesEditorCtrl | undefined;
}

export interface PromptEditorOpsContextProps {
  updateFields: (fields: EditorUpdatePromptFields["fields"]) => void;
}

export interface PromptEditorStateContextProps {
  name: string;
  definition: PromptDefinition;
  index: number;
  didEnterView: boolean;
  nextKeysHidden: boolean;
  control: PromptEditorCtrl;
}

export const PromptEditorOpsContext = React.createContext<
  PromptEditorOpsContextProps | undefined
>(undefined);

export const PromptEditorStateContext = React.createContext<
  PromptEditorStateContextProps | undefined
>(undefined);

export function usePromptEditorOps(): PromptEditorOpsContextProps {
  const context = React.useContext(PromptEditorOpsContext);
  if (context === undefined) {
    throw new Error(
      "usePromptEditorOps can only be used within a PromptEditor"
    );
  }
  return context;
}

export function usePromptEditorState(): PromptEditorStateContextProps {
  const context = React.useContext(PromptEditorStateContext);
  if (context === undefined) {
    throw new Error(
      "usePromptEditorState can only be used within a PromptEditor"
    );
  }
  return context;
}

export interface PromptEditorProps {
  name: string;
  definition: PromptDefinition;
  index: number;
  disableDragDrop?: boolean | null;
  pinInsertButton?: boolean | null;
  control?: (control: PromptEditorCtrl) => void;
  deregisterControl?: (controlKey: string) => void;
}

function PromptEditor({
  name,
  definition,
  index,
  disableDragDrop,
  pinInsertButton,
  control: registerControl,
  deregisterControl,
}: PromptEditorProps): ReactElement | null {
  const dispatch = useDispatch();

  const easyName = useSelector((state: RootState) => {
    const promptEasyNames = selectPromptEasyNames(state);
    return promptEasyNames?.toEasyName(name);
  });

  const isPreviewing = useSelector((state: RootState) => {
    return state.flowEditor.previewPromptIndex === index;
  });
  const isPromptConfigOpen = useSelector(selectIsPromptConfigOpen);

  const isStartPrompt = name === "start";
  const nextKeyStatus = ops.getPromptNextKeyStatus(definition);

  const language = useSelector(selectCurrentLanguage);
  const isTranslated = useMemo(
    () => !language || isPromptTranslated(definition, language),
    [definition, language]
  );

  const [isHovering, setIsHovering] = useState<boolean>(false);

  const isPromptQuickRespEnabled = ops.shouldEnablePromptQuickResponses(
    definition
  );

  const enterViewTimeoutRef = useRef<number>();
  const [didEnterView, setDidEnterView] = useState<boolean>(false);
  const [inViewRef] = useInView({
    threshold: 0,
    onEnter: () => {
      enterViewTimeoutRef.current = window.setTimeout(
        () => setDidEnterView(true),
        300
      );
    },
    onLeave: () => {
      if (enterViewTimeoutRef.current) {
        clearTimeout(enterViewTimeoutRef.current);
        enterViewTimeoutRef.current = undefined;
      }
    },
  });

  function previewPromptHandler(): void {
    dispatch(previewPrompt({ indexOrName: index }));
  }

  function openPromptConfigHandler(): void {
    dispatch(setIsPromptConfigOpen(true));
  }

  function duplicatePromptHandler(): void {
    dispatch(insertPrompt({ definition, index: index + 1 }));
  }

  function removePromptHandler(): void {
    const confirmMessage =
      "You may lose some linking between prompts." +
      " Do you want to go ahead?";

    if (window.confirm(confirmMessage)) {
      dispatch(removePrompt({ indexOrName: index }));
    }
  }

  function updateFields(fields: EditorUpdatePromptFields["fields"]) {
    dispatch(
      updatePromptFields({
        promptIndexOrName: name,
        fields,
      })
    );
  }

  function buildIcon(): ReactNode {
    if (definition.inputDisplayType === "AwsImageUpload") {
      return (
        <MyTippy content="Image upload">
          <ImageUploadIcon className={styles.icon} />
        </MyTippy>
      );
    } else {
      return (
        <MyTippy content="Q and A">
          <QuestionAnswerIcon className={styles.icon} />
        </MyTippy>
      );
    }
  }

  const [isErrorsOpen, setIsErrorsOpen] = useState(false);
  const {
    value: isActionMenuOpen,
    setTrueWithDelay: openActionMenuWithDelay,
    setTrueImmediate: openActionMenu,
    setFalseWithDelay: closeActionMenuWithDelay,
    setFalseImmediate: closeActionMenu,
    toggleImmediate: toggleActionMenu,
  } = useDelayedToggle(false, {});

  function buildTopBar(
    dragHandleProps: DraggableProvidedDragHandleProps | undefined
  ): ReactNode {
    const definitionErrors: string[] = [];
    if (nextKeyStatus === "danglingAnswers") {
      definitionErrors.push("All answers must lead to some prompt");
    } else if (nextKeyStatus === "danglingMultiSelect") {
      definitionErrors.push(
        "'Always to' must be provided when allowing multiple select"
      );
    }

    return (
      <div className={styles.topBar}>
        <div
          className={classNames(styles.dragHandle, {
            [styles.dragHandle__hidden]: disableDragDrop,
          })}
          {...dragHandleProps}
        >
          <GrDrag size={14} />
        </div>
        {buildIcon()}
        <div className={styles.name}>
          <FlatButton
            className={styles.name_button}
            onClick={previewPromptHandler}
            onDoubleClick={openPromptConfigHandler}
          >
            Prompt {easyName}
          </FlatButton>
          {isStartPrompt && (
            <div
              className={classNames(
                styles.name_suffix,
                styles.name_suffix__start
              )}
            >
              Start
            </div>
          )}
          {definition.answerExternally && (
            <div
              className={classNames(
                styles.name_suffix,
                styles.name_suffix__pause
              )}
            >
              Pause
            </div>
          )}
          {nextKeyStatus === "terminal" && (
            <div
              className={classNames(
                styles.name_suffix,
                styles.name_suffix__terminal
              )}
            >
              Finish
            </div>
          )}
          {!isTranslated && (
            <div
              className={classNames(
                styles.name_suffix,
                styles.name_suffix__i18nMissing
              )}
            >
              <TranslateIcon size={12} language={language} />
              &nbsp;Missing
            </div>
          )}
          {definitionErrors.length > 0 && (
            <Popover
              key="more"
              positions={["bottom", "top"]}
              reposition
              isOpen={isErrorsOpen}
              onClickOutside={() => setIsErrorsOpen(false)}
              containerClassName={styles.definitionErrorsContainer}
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                  childRect={childRect}
                  popoverRect={popoverRect}
                  position={position}
                  arrowSize={10}
                  arrowColor="var(--danger)"
                >
                  <div className={styles.definitionErrorsContent}>
                    <ul>
                      {definitionErrors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                </ArrowContainer>
              )}
            >
              <FlatButton
                className={styles.name_definitionErrors}
                onClick={() => setIsErrorsOpen((v) => !v)}
              >
                <BsExclamationCircle />
              </FlatButton>
            </Popover>
          )}
        </div>
        <Popover
          positions={["bottom", "top"]}
          align="end"
          reposition
          isOpen={isActionMenuOpen}
          onClickOutside={closeActionMenu}
          containerClassName={styles.actionMenu}
          content={
            <Menu
              autoClose
              closeOnEsc
              onRequestClose={closeActionMenu}
              onPointerEnter={openActionMenu}
              onPointerLeave={closeActionMenu}
            >
              {isPromptConfigOpen ? null : (
                <MenuItem
                  onClick={() => {
                    previewPromptHandler();
                    openPromptConfigHandler();
                  }}
                >
                  <FaWrench />
                  &nbsp;Prompt Settings
                </MenuItem>
              )}
              {isPromptQuickRespEnabled && definition.quickResponses == null && (
                <MenuItem
                  onClick={() => {
                    dispatch(
                      initPromptQuickResponses({
                        promptIndexOrName: index,
                      })
                    );
                  }}
                >
                  <FaReplyAll />
                  &nbsp;Quick Resp. List
                </MenuItem>
              )}
              <MenuItem onClick={duplicatePromptHandler}>
                <IoDuplicateOutline />
                &nbsp;Duplicate Prompt
              </MenuItem>
              <MenuItem
                autoClose={false}
                onClick={removePromptHandler}
                disabled={isStartPrompt}
              >
                <FaRegTrashAlt />
                &nbsp;Delete Prompt&nbsp;
                {isStartPrompt && (
                  <HelpTip
                    label="why?"
                    tooltipContent="The start prompt can't be deleted"
                  />
                )}
              </MenuItem>
            </Menu>
          }
        >
          <FlatButton
            className={styles.actionMenuButton}
            onPointerEnter={openActionMenuWithDelay}
            onPointerLeave={closeActionMenuWithDelay}
          >
            <MdMoreVert size={18} />
          </FlatButton>
        </Popover>
      </div>
    );
  }

  const editorControl = useRef<MessageEditorCtrl>();
  const registerEditorControl = useCallback(
    (c) => (editorControl.current = c),
    []
  );

  const answersControl = useRef<AnswersEditorCtrl>();
  const registerAnswersControl = useCallback(
    (c) => (answersControl.current = c),
    []
  );

  const messagesControl = useRef<MessagesEditorCtrl>();
  const registerMessagesControl = useCallback(
    (c) => (messagesControl.current = c),
    []
  );

  const altMessagesControl = useRef<AltMessagesEditorCtrl>();
  const registerAltMessageControl = useCallback(
    (c) => (altMessagesControl.current = c),
    []
  );

  const control: PromptEditorCtrl = useMemo(
    () => ({
      key: name,
      index,
      focus: () => {
        editorControl.current?.focus();
      },
      answers: () => answersControl.current,
      messages: () => messagesControl.current,
      altMessages: () => altMessagesControl.current,
    }),
    [name, index]
  );

  useEffect(() => {
    registerControl?.(control);
  }, [registerControl, control]);

  useEffect(() => {
    return function cleanup() {
      deregisterControl?.(name);
    };
  }, [deregisterControl, name]);

  function buildMessageEditor(): ReactNode {
    if (definition.messages != null) {
      return <MessagesEditor control={registerMessagesControl} />;
    } else {
      return (
        <MessageEditor
          message={definition.message}
          contentType={definition.contentType}
          onChange={(message) => updateFields({ message })}
          onKeyDown={(event) => {
            if (event.ctrlKey || event.metaKey) {
              if (
                event.key === "Enter" &&
                definition.inputDisplayType !== "AwsImageUpload"
              ) {
                dispatch(
                  insertAnswer({
                    promptIndexOrName: index,
                    definition: null,
                    index: 0,
                  })
                );
                setTimeout(() => answersControl.current?.items?.[0].focus(), 0);
              } else if (event.key === "/") {
                toggleActionMenu();
                return true;
              }
            }
            return false;
          }}
          translations={definition.tMessage}
          onTranslationChange={(language, message) => {
            updateFields({
              tMessage: assignTranslation(
                definition.tMessage,
                language,
                message
              ),
            });
          }}
          control={registerEditorControl}
        />
      );
    }
  }

  function buildAnswersEditor(): ReactNode {
    if (definition.inputDisplayType === "AwsImageUpload") {
      return (
        <AnswersEditor
          answerOptions={{
            addDisabled: true,
            messageDisabled: true,
            nextKeyHidden: true,
            quickResponseDisabled: true,
          }}
          control={registerAnswersControl}
        />
      );
    } else {
      return <AnswersEditor control={registerAnswersControl} />;
    }
  }

  function buildAltMessagesEditor(): ReactNode {
    return (
      <AltMessagesEditor
        messages={definition.quickResponses}
        onChange={(messages) =>
          updateFields({
            quickResponses: messages,
          })
        }
        control={registerAltMessageControl}
      />
    );
  }

  if (easyName == null) return null;

  let markerColor = "transparent";
  if (isStartPrompt) {
    markerColor = "var(--startColor)";
  }
  if (nextKeyStatus === "terminal") {
    markerColor = "var(--terminalColor)";
  }
  if (definition.answerExternally) {
    markerColor = "var(--pauseColor)";
  }
  if (!isTranslated) {
    markerColor = "var(--i18n)";
  }
  if (nextKeyStatus === "danglingAnswers") {
    markerColor = "var(--danger)";
  }

  return (
    <Draggable draggableId={name} index={index}>
      {(provided) => {
        return (
          <PromptEditorStateContext.Provider
            value={{
              name,
              definition,
              index,
              didEnterView,
              nextKeysHidden:
                definition.nextKey != null ||
                !!definition.acceptsMultipleAnswers,
              control,
            }}
          >
            <PromptEditorOpsContext.Provider value={{ updateFields }}>
              <div {...provided.draggableProps} ref={provided.innerRef}>
                <Marker id={name} color={markerColor} />
                <div
                  className={classNames(styles.root, {
                    [styles.root__isPreviewing]: isPreviewing,
                  })}
                  onPointerEnter={() => setIsHovering(true)}
                  onPointerLeave={() => setIsHovering(false)}
                >
                  <div ref={inViewRef} style={{ height: 0 }} />
                  <div className={styles.card}>
                    {isPreviewing && isPromptConfigOpen && (
                      <div className={styles.leftArrow} />
                    )}
                    {buildTopBar(provided.dragHandleProps)}
                    <div className={styles.message}>{buildMessageEditor()}</div>
                    <div className={styles.answers}>{buildAnswersEditor()}</div>
                    {isPromptQuickRespEnabled &&
                      definition.quickResponses?.length && (
                        <div className={styles.altMessages}>
                          {buildAltMessagesEditor()}
                        </div>
                      )}
                    <div className={styles.nextKey}>
                      <NextKeyEditor
                        label="Always to"
                        promptIndex={index}
                        initialNextKey={definition.nextKey}
                        onChange={(key) =>
                          updateFields({ nextKey: key ?? null })
                        }
                      />
                    </div>
                    {(pinInsertButton || isHovering) && (
                      <InsertPromptButton index={index + 1} />
                    )}
                  </div>
                </div>
              </div>
            </PromptEditorOpsContext.Provider>
          </PromptEditorStateContext.Provider>
        );
      }}
    </Draggable>
  );
}

export default memo(PromptEditor);
